<template>
<div class="container">
  <vs-row>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <h1 class="text-primary">Valida tus documentos</h1>
    </vs-col>
  </vs-row>

  <vs-row class="pt-5">

    <!--PAdES-->
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-sm="12" vs-lg="5" class="p-5 m-auto">
      <vx-card>
        <vs-row>
          <div class="vx-col lg:w-1/4 m-auto">
            <img class="min-w-img" src="@/assets/images/pages/validator/pdf.svg">
          </div>
        </vs-row>

        <vs-row>
          <div class="vx-col m-auto text-center">
            <h3 class="text-primary mt-5 ">Validador PDF (PAdES)</h3>
            <p class="p-5">Puedes validar tus documentos firmados en formato PDF de acuerdo al estándar PAdES
              y determinar si el documento fue firmado correctamente por todos los involucrados.
            </p>
            <router-link to="/validador-pdf-signature">
              <vs-button color="warning" type="filled">Validar</vs-button>
            </router-link>
          </div>
        </vs-row>

      </vx-card>
    </vs-col>

    <!-- XML -->
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-sm="12" vs-lg="5" class="p-5 m-auto">
      <vx-card>
        <vs-row>
          <div class="vx-col lg:w-1/4 m-auto">
            <img class="min-w-img" src="@/assets/images/pages/validator/xml.svg">
          </div>
        </vs-row>

        <vs-row>
          <div class="vx-col m-auto text-center">
            <h3 class="text-primary mt-5 ">Validador Integral DigitaFirma (XML)</h3>
            <p class="p-5">Verifica el archivo XML generado por DigitaFirma para validar integralmente
              la huella digital del documento, las firmas asociadas a el
              y las constancias de conservación de datos NOM-151.
            </p>
            <router-link to="/validador-xml"><vs-button color="warning" type="filled">Validar</vs-button></router-link>
          </div>
        </vs-row>

      </vx-card>
    </vs-col>


  </vs-row>

</div>
</template>

<script>
export default {
  data () {
    return {
      activePrompt: false,
      signatureType: 'pades'
    }
  },
  methods:{
    acceptAlert () {
      if (this.signatureType === 'fea') {
        this.$router.push({ name: 'V-sign' })
      } else {
        this.$router.push({ name: 'V-pdf' })
      }
    },
    close () {
      console.log('Se cierra el alert')
    }
  }
}
</script>
